import { useFormik } from "formik"


export const useCustomFormik = (initialValues, validationSchema, onSubmit) => {

    return useFormik({
        initialValues,
        validationSchema,
        onSubmit
    })

}