import React from 'react'
import "../Components/Purchase/Purchase.css"
import PurchaseForm from '../Components/Purchase/PurchaseForm'

const Purchase = () => {
    return (
        <>
            <div className="mt-2 pt-1">
                <h1>Purchase</h1>
                <PurchaseForm />
            </div>
        </>
    )
}

export default Purchase