
import * as Yup from 'yup';

export const creditReceiveValidationSchema = Yup.object().shape({
    creditReceive: Yup.number()
        .required('Credit receive is required')
        .min(1, 'Credit receive cannot be Rs 0')
        .test('credit-receive-not-exceed', 'Credit receive cannot exceed the remaining balance', function (value) {
            const { creditRemaining } = this.parent;
            return value <= creditRemaining;
        }),
    receiveDate: Yup.date().required('Receive date is required'),
    remainingDate: Yup.date()
        .nullable()
        .when(['creditRemaining', 'creditReceive'], {
            is: (creditRemaining, creditReceive) => creditRemaining - creditReceive > 0,
            then: (schema) => schema.required('Remaining date is required'),
            otherwise: (schema) => schema.nullable(),
        }),
    creditRemaining: Yup.number(),
});
