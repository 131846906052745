export const handleAdvanceChange = (formik, totalAmount, setIsAdvanceValid) => (e) => {
    const value = e.target.value;

    // Validate if the input is a number or empty
    if (/^\d*\.?\d*$/.test(value)) {
        formik.setFieldValue("advanceAmount", value);

        // Calculate creditAmount based on totalAmount and advanceAmount
        const advance = parseFloat(value) || 0;
        const creditAmount = totalAmount - advance;

        // Update the credit amount in Formik state (not to be submitted)
        formik.setFieldValue("creditAmount", creditAmount);

        // Check if advance exceeds totalAmount
        if (advance > totalAmount) {
            formik.setFieldError("advanceAmount", "Advance amount cannot exceed total amount.");
            setIsAdvanceValid(false);
        } else {
            formik.setFieldError("advanceAmount", null);
            setIsAdvanceValid(true);
        }
    }
};
