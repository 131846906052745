import * as Yup from 'yup';

export const purchaseValidationSchema = Yup.object({
    productName: Yup.string()
        .matches(/^[a-zA-Z0-9\s]+$/, "Product name must contain letters and numbers only")
        .max(50, "Product name must be at most 50 characters")
        .min(3, "Product name must be at least 3 characters")
        .required('Product name is required'),
    purchasePrice: Yup.number()
        .min(1, "Price must be at least Rs 1")
        .typeError('Purchase price must be a valid number')
        .required('Purchase price is required'),
    purchaseDate: Yup.date()
        .required('Purchase Date is required'),
    quantity: Yup.number()
        .min(1, 'Quantity must be at least 1')
        .typeError('Quantity must be valid a number')
        .required('Quantity is required'),
    color: Yup.string()
        .required('Color is required'),
});



