// export const formatCurrency = (amount) => {
//     if (amount == null) return '0.0';  // Return '0.0' if amount is null or undefined
//     return parseFloat(amount).toLocaleString('en-PK', {
//         style: 'currency',
//         currency: 'PKR',
//         minimumFractionDigits: 2,
//     });
// };


export const formatCurrency = (amount) => {
    if (amount == null) return '0.0';  // Return '0.0' if amount is null or undefined

    const isNegative = amount < 0;  // Check if the amount is negative
    const formattedAmount = Math.abs(parseFloat(amount)).toLocaleString('en-PK', {
        style: 'decimal',  // Use decimal style for formatting
        minimumFractionDigits: 2,
    });

    return 'Rs' + (isNegative ? ' -' : ' ') + formattedAmount; // Concatenate '-' if negative
};
