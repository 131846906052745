import React from 'react'
import InventoryGrid from '../Components/Inventory/InventoryGrid'

const Inventory = () => {
    return (
        <>
            <div className="mt-2 pt-1">
                <h1 >Inventory</h1>
                <div className='mt-3'>
                    <InventoryGrid />
                </div>
            </div>
        </>
    )
}

export default Inventory