
import React from "react";
import { components } from "react-select";
import { IoMdSearch } from 'react-icons/io';

const { DropdownIndicator } = components;

const CustomDropdownIndicator = (props) => (
    <DropdownIndicator {...props} className='searchIconColor'>
        <IoMdSearch size={20} />
    </DropdownIndicator>
);

export default CustomDropdownIndicator;
