import React from 'react'
import CreditHistoryGrid from '../Components/Credit/creditHistory/CreditHistoryGrid.js'

const CreditHistory = () => {
    return (
        <>
            <div className="mt-2 pt-1">
                <h1 >Credit History</h1>
                <div className='mt-3'>
                    <CreditHistoryGrid />
                </div>
            </div>
        </>
    )
}

export default CreditHistory