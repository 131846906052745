
export const validateSaleAmount = (saleAmount, quantitySold, avgPrice, setSaleAmountWarning) => {
    const minimumSaleAmount = quantitySold * avgPrice;

    // Check if the sale amount is less than the minimum sale amount
    if (saleAmount < minimumSaleAmount) {
        setSaleAmountWarning(`Minimum sale amount should be Rs ${avgPrice * quantitySold}`);
    } else {
        setSaleAmountWarning(""); // Clear warning if condition is not met
    }
};