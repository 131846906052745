import axios from "axios"
import { showToast } from "../../../utils/handlers/toastHandler"
import { COLOR_API_END_POINT } from "../../../utils/constants/apiUrls"


export const allColors = async () => {
    try {

        const result = await axios.get(`${COLOR_API_END_POINT}/getAllColors`, {
            withCredentials: true
        })

        return result.data

    } catch (error) {
        if (error.response) {
            const { status, data } = error.response

            showToast(status, data.message)

        } else {
            showToast(500, "Some error occured")
        }
    }
}