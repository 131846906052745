// components/ButtonComponent.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { useNavigate } from 'react-router-dom';

const ButtonComponent = ({ customerName }) => {
    const navigate = useNavigate();

    const handleViewClick = () => {
        navigate('/credithistory', { state: { customerName } });
    };

    return (
        <div className='row'>
            <div className='' onClick={handleViewClick}>
                <div className='ms-3'>
                    <FontAwesomeIcon icon={faEye} title='View Customer' />
                </div>
            </div>
        </div>
    );
};

export default ButtonComponent;
