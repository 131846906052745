import React from 'react';
import '../Purchase/Purchase.css'
import './User.css'
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { useCustomFormik } from '../../configs/formik/formikConfig';
import { loginValidationSchema } from '../../configs/formik/validationSchema/loginValidationSchema';
import { showToast } from '../../utils/handlers/toastHandler';
import axios from 'axios';
import { USER_API_END_POINT } from '../../utils/constants/apiUrls';
import { setUser } from '../../states/redux/authslice';


const LogIn = () => {

    // const { user } = useSelector(store => store.auth)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const formik = useCustomFormik({
        email: '',
        password: ''
    }, loginValidationSchema, async (values) => {
        // console.log('Form Data Submitted:', values);

        try {

            const result = await axios.post(`${USER_API_END_POINT}/userLogin`, values, {
                headers: {
                    "Content-Type": "application/json"
                },
                withCredentials: true
            })

            if (result.data.success) {
                dispatch(setUser(result.data.userResponse))
                showToast(result.status, result.data.message)
                navigate("/reports")
            }


        } catch (error) {
            // console.log(error)
            if (error.response) {
                const { status, data } = error.response

                showToast(status, data.message)
                // navigate("/reports")

            } else {
                showToast(500, "Some error occured")
            }
        }


    })

    return (
        <>
            <div className="sign-up-container text-center pt-5">
                <form className="sign-up-form" onSubmit={formik.handleSubmit}>
                    <h4 className="pb-3 ">Log in</h4>
                    <input
                        placeholder="Email"
                        type="text"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={` ${formik.touched.email && formik.errors.email ? 'error-input' : ''}`}
                    />
                    {formik.touched.email && formik.errors.email && (
                        <div className="error">{formik.errors.email}</div>
                    )}
                    <input
                        placeholder="Password"
                        type="password"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={` ${formik.touched.password && formik.errors.password ? 'error-input' : ''}`}
                        minLength={3}
                        maxLength={100}
                    />
                    {formik.touched.password && formik.errors.password && (
                        <div className="error">{formik.errors.password}</div>
                    )}


                    <button className="btn btn-secondary mt-2 mb-2" type="submit">
                        Login
                    </button>

                    <h6 className="pt-3">Don't have an account?
                        <NavLink className='ms-1 customLink' to='/signup'>
                            Signup here
                        </NavLink>
                    </h6>
                </form>
            </div>
        </>
    );
};

export default LogIn;
