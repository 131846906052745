import * as Yup from 'yup';

export const expenseValidationSchema = Yup.object({
    amount: Yup.number()
        .min(1, "Expense amount must be at least Rs 1")
        .typeError("Enter a valid number")
        .required('Expense amount is required'),
    expenseDate: Yup.date()
        .required("Expense date is required"),
    description: Yup.string()
        .max(200, "Description length must be at most 200 characters")
        .required("Expense description is required"),
})