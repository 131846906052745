import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { showToast } from '../../../utils/handlers/toastHandler';
import { handleCustomerInputChange } from '../../../utils/handlers/inputChangeHandlers';
import CustomDropdownIndicator from '../../Common/customDropDownIndcator';
import { Input } from '../../Common/SelectInputLength';
import { customStyles } from '../../Purchase/CustomStyleSelect';
import { fetchCreditHistory } from '../services/creditServices';

const CreditHistoryGrid = () => {
    const [customerOptions, setCustomerOptions] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]); // New state to store the response data
    const location = useLocation();

    const defaultColDef = {
        flex: 1,
        minWidth: 150,
        sortable: false,
        resizable: false,
        suppressMovable: true,
    };

    // Column Definitions: Defines the columns to be displayed.
    const columnDefs = [
        {
            headerName: "Credit Balance",
            field: "previous_credit_balance",
            valueFormatter: (params) => {
                const balance = params.value;
                // Check if the balance is null or undefined
                if (balance === null || balance === undefined) {
                    return 'Rs 0.00'; // Placeholder if balance is missing
                }
                return `Rs ${parseFloat(balance).toFixed(2)}`; // Format balance to 2 decimal places
            }
        },
        {
            headerName: "Credit Received",
            field: "credit_received",
            valueFormatter: p => "Rs " + parseFloat(p.value).toLocaleString(),
        },
        {
            headerName: "Received Date",
            field: "received_date",
            valueFormatter: (params) => {
                const dateValue = params.value;
                // Check if the due_date is null, undefined, or an empty string
                if (!dateValue) {
                    return 'No Due Date'; // Return a placeholder if no date is available
                }
                const date = new Date(dateValue);
                // Check if the date is valid
                if (isNaN(date.getTime())) {
                    return 'Invalid Date'; // Handle invalid dates
                }
                return date.toLocaleDateString(); // Return the formatted date
            },
        },
        {
            headerName: "Credit Remaining",
            field: "credit_remaining",
            valueFormatter: (params) => {
                const balance = params.value;
                // Check if the balance is null or undefined
                if (balance === null || balance === undefined) {
                    return 'Rs 0.00'; // Placeholder if balance is missing
                }
                return `Rs ${parseFloat(balance).toFixed(2)}`; // Format balance to 2 decimal places
            }
        },
        {
            headerName: "Due Date",
            field: "due_date",
            valueFormatter: (params) => {
                const dateValue = params.value;
                // Check if the due_date is null, undefined, or an empty string
                if (!dateValue) {
                    return 'No Due Date'; // Return a placeholder if no date is available
                }
                const date = new Date(dateValue);
                // Check if the date is valid
                if (isNaN(date.getTime())) {
                    return 'Invalid Date'; // Handle invalid dates
                }
                return date.toLocaleDateString(); // Return the formatted date
            },
        },
    ];

    // Fetch credit history based on customer name
    const getCreditHistory = useCallback(async (customerName) => {
        try {
            const data = await fetchCreditHistory(customerName);
            setRowData(data); // Update grid data
        } catch (error) {
            showToast(500, error.message || 'Some error occurred');
        }
    }, []);

    // Effect to auto-select customer from URL state
    useEffect(() => {
        if (location.state?.customerName) {
            const customerName = location.state.customerName;
            setSelectedCustomer({ label: customerName, value: customerName });
            getCreditHistory(customerName); // Fetch credit history automatically
        }
    }, [location.state, getCreditHistory]);

    const handleCustomerChange = (selectedOption) => {
        setSelectedCustomer(selectedOption.label.split(" (")[0]);
        if (selectedOption) {
            getCreditHistory(selectedOption.label.split(" (")[0]);
        }
    };

    return (
        <>
            <div className="contactUsFormContainer ">
                <div className='contactUsForm pb-4'>
                    <div className='row'>
                        <div className='col-12 col-lg-6'>
                            <label htmlFor='customerName'>Customer</label>
                            <Select
                                id='customerName'
                                placeholder="Select Customer"
                                options={customerOptions}
                                onInputChange={handleCustomerInputChange(setCustomerOptions)}
                                onChange={handleCustomerChange}
                                value={selectedCustomer}
                                styles={customStyles}
                                components={{ DropdownIndicator: CustomDropdownIndicator, Input }}
                                noOptionsMessage={() => 'Customer does not exist'}
                                isSearchable
                                className={`mb-2`}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="ag-theme-quartz mt-4 " style={{ height: 390 }}>
                <AgGridReact
                    ref={gridRef}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    rowData={rowData}
                    alwaysShowHorizontalScroll={true}
                />
            </div>
        </>
    );
};

export default CreditHistoryGrid;
