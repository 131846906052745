import React from 'react'
import CreditReceiveForm from '../Components/Credit/creditReceive/CreditReceiveForm'

const CreditReceive = () => {
    return (
        <>
            <div className="mt-2 pt-1">
                <h1 >Credit Received</h1>
                <CreditReceiveForm />
            </div>
        </>
    )
}

export default CreditReceive