import React, { useCallback, useRef, useState } from 'react';
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { INVENTORY_API_END_POINT } from '../../utils/constants/apiUrls';
import { showToast } from '../../utils/handlers/toastHandler';
import { handleAlphabetsKeyDown, handleAlphanumericKeyDown } from '../../utils/handlers/keyDownHandlers';

const InventoryGrid = () => {
    const gridRef = useRef();
    const [filterText, setFilterText] = useState('');

    const defaultColDef = {
        flex: 1, // Allow columns to take up the remaining space
        minWidth: 150, // Ensure columns have a minimum width
        sortable: false,
        resizable: false,
        suppressMovable: true,
    };

    const columnDefs = [
        { headerName: "Product name", field: 'product_name' },
        {
            headerName: "Average price", field: 'average_price',
            valueFormatter: p => "Rs " + parseFloat(p.value).toLocaleString(),
        },
        { headerName: "Quantity", field: 'quantity' },
        { headerName: "Color", field: 'color' },
    ];

    const onGridReady = async (params) => {
        try {
            const response = await axios.get(`${INVENTORY_API_END_POINT}/getInventory`, {
                withCredentials: true,
            });

            if (response.data) {
                params.api.applyTransaction({ add: response.data });
            }
        } catch (error) {
            showToast(500, 'Some error occurred');
        }
    };

    // Custom filter logic to filter only based on the product_name
    const doesExternalFilterPass = useCallback((node) => {
        return node.data.product_name.toLowerCase().includes(filterText.toLowerCase());
    }, [filterText]);

    const isExternalFilterPresent = useCallback(() => {
        return filterText !== '';
    }, [filterText]);

    const onFilterTextBoxChanged = useCallback(() => {
        const filterValue = document.getElementById("filter-text-box").value;
        setFilterText(filterValue);
        gridRef.current.api.onFilterChanged();
    }, []);

    return (
        <>
            <div className="contactUsFormContainer ">
                <div className='contactUsForm pb-4'>
                    <div className='row'>
                        <div className='col-12 col-lg-6'>
                            <label htmlFor='productName'>Product</label>
                            <input
                                type='text'
                                id='filter-text-box'
                                name='productName'
                                placeholder="Search product"
                                onKeyDown={handleAlphanumericKeyDown}
                                onInput={onFilterTextBoxChanged}
                                maxLength={50}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="ag-theme-quartz mt-4 " style={{ height: 390 }}>
                <AgGridReact
                    ref={gridRef}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    onGridReady={onGridReady}
                    doesExternalFilterPass={doesExternalFilterPass}
                    isExternalFilterPresent={isExternalFilterPresent}
                    pagination={true}
                    paginationPageSizeSelector={false}
                    paginationPageSize={10}
                />
            </div>
        </>
    );
};

export default InventoryGrid;
