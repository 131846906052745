// ConfirmationModal.js
import React from 'react';
import { CDBModal, CDBModalBody, CDBModalFooter } from 'cdbreact';

const ConfirmationModal = ({ isOpen, toggle, onConfirm, message }) => {
    return (
        <CDBModal isOpen={isOpen} toggle={toggle} className="centered-modal">
            <div className="modalHeader">Confirm Action</div>
            <CDBModalBody>{message}</CDBModalBody>
            <CDBModalFooter>
                <button className="btn btn-sm btn-secondary me-2" onClick={toggle}>
                    Cancel
                </button>
                <button className="btn btn-sm btn-danger" onClick={onConfirm}>
                    Yes, Confirm
                </button>
            </CDBModalFooter>
        </CDBModal>
    );
};

export default ConfirmationModal;
