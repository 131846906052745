import React, { useEffect, useState } from 'react';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/DateRangePicker/styles/index.css';
import './Report.css';
import { formatDate } from './utils/utils';
import { getAllCreditRemaining, getReports } from './services/reportAPI';
import { formatCurrency } from '../../utils/currencyFormat';
import ReportCard from '../Common/ReportCard';

const ReportScreen = () => {
    const [reportsData, setReportsData] = useState();
    const [dateRange, setDateRange] = useState(null);
    const [creditRemaining, setCreditRemaining] = useState(0);

    useEffect(() => {
        const fetchCreditRemaining = async () => {
            try {
                const data = await getAllCreditRemaining();
                setCreditRemaining(data?.creditRemaining || 0);
            } catch (error) {
                console.error("Error fetching credit remaining:", error);
            }
        };

        fetchCreditRemaining();
    }, []);

    const handleDateRange = async (range) => {
        setDateRange(range);
        if (range) {
            const startDate = formatDate(range[0]);
            const endDate = formatDate(range[1]);

            // calling API to get reports
            const data = await getReports(startDate, endDate);
            setReportsData(data);
        }
    };

    const handleReset = () => {
        setDateRange(null);
        setReportsData(null);
    };

    return (
        <>
            <h1>Reports</h1>
            <div className='d-lg-flex'>
                <div className='date-range-picker-container col-lg-8 col-12  pt-4 '>
                    <DateRangePicker
                        format="yyyy-MM-dd"
                        placeholder="Select Date Range"
                        showOneCalendar
                        editable={false}
                        onChange={handleDateRange}
                        value={dateRange}
                    />
                    <button className="btn btn-secondary ms-md-3 mt-md-0 mt-3" onClick={handleReset}>Reset</button>
                </div>
                <div className='ms-lg-3 col-lg-4 col-12'>
                    <ReportCard title={"Credit Remaining"} value={formatCurrency(creditRemaining)} />
                </div>
            </div>
            <div className=''>
                <div className='row mt-lg-4'>
                    <ReportCard title={"Profit"} value={formatCurrency(reportsData?.profit)} />
                    <ReportCard title={"Cash Sales"} value={formatCurrency(reportsData?.cashSale)} />
                    <ReportCard title={"Credit Sales"} value={formatCurrency(reportsData?.creditSale)} />
                    <ReportCard title={"Credit Received"} value={formatCurrency(reportsData?.creditReceived)} />
                    <ReportCard title={"Purchase"} value={formatCurrency(reportsData?.purchase)} />
                    <ReportCard title={"Expense"} value={formatCurrency(reportsData?.expense)} />
                </div>
            </div>
        </>
    );
};

export default ReportScreen;
