import "./Sidebar.css";
import React, { useState } from "react";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBModal,
  CDBModalBody,
  CDBModalFooter,
} from "cdbreact";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutUser } from "../User/logout/services/logoutService";
import { logout } from "../../states/redux/authslice";
import { showToast } from "../../utils/handlers/toastHandler";


const Sidebar = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Handle logout when confirmed
  const handleLogout = async () => {
    try {
      // Call the logout API from the service
      await logoutUser();

      // Dispatch the logout action to update the state
      dispatch(logout());

      // Redirect the user to the login page
      navigate("/login");
    } catch (error) {
      showToast(400, "Some error occured")
    } finally {
      setModalOpen(false); // Close modal after logout
    }
  };

  // Toggle the modal
  const toggleModal = () => setModalOpen(!modalOpen);

  return (
    <div style={{ display: "flex", minHeight: "100vh" }}>
      <CDBSidebar className="sidebar">
        <CDBSidebarHeader prefix={<i className="fa fa-bars" />}>
          <a href="/#" className="text-decoration-none" style={{ color: "inherit" }}>
            LogoHere
          </a>
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
            <NavLink to="/purchase" className="nav-link">
              <CDBSidebarMenuItem icon="shopping-cart">Purchase</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/sale" className="nav-link">
              <CDBSidebarMenuItem icon="chart-line">Sale</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/expense" className="nav-link">
              <CDBSidebarMenuItem icon="file-invoice-dollar">Expense</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/customer" className="nav-link">
              <CDBSidebarMenuItem icon="users">All Customers</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/inventory" className="nav-link">
              <CDBSidebarMenuItem icon="clipboard-list">Inventory</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/creditreceive" className="nav-link">
              <CDBSidebarMenuItem icon="credit-card">Credit Receive</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/credithistory" className="nav-link">
              <CDBSidebarMenuItem icon="history">Credit History</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/reports" className="nav-link">
              <CDBSidebarMenuItem icon="file-alt">Reports</CDBSidebarMenuItem>
            </NavLink>
          </CDBSidebarMenu>
        </CDBSidebarContent>

        <CDBSidebarFooter>
          <div className="nav-link" style={{ cursor: "pointer" }} onClick={toggleModal}>
            <CDBSidebarMenuItem icon="sign-out-alt">Logout</CDBSidebarMenuItem>
          </div>
        </CDBSidebarFooter>
      </CDBSidebar>

      {/* Logout Confirmation Modal */}
      <CDBModal isOpen={modalOpen} toggle={toggleModal} className="centered-modal" >
        <div className="modalHeader" toggle={toggleModal}>Confirm Logout</div>
        <CDBModalBody>Are you sure you want to log out?</CDBModalBody>
        <CDBModalFooter>
          <button className='btn btn-sm btn-secondary me-2' onClick={toggleModal}>Cancel</button>
          <button className='btn btn-sm btn-danger' onClick={handleLogout}>Yes, Logout</button>

        </CDBModalFooter>
      </CDBModal>
    </div>
  );
};

export default Sidebar;
