// utils/handlers/productHandlers.js

import { showToast } from "../../../utils/handlers/toastHandler";

export const addProduct = (selectedProduct, quantitySold, saleAmount, color, avgPrice, setProducts, availableQuantities) => {
    if (!selectedProduct || !quantitySold || !saleAmount) {
        showToast(400, "Please complete all fields before adding the product.");
        return null;
    }

    const uniqueId = `${Date.now()}-${Math.floor(Math.random() * 100000)}`;

    // Calculate the updated available quantity for the selected product
    const currentAvailableQuantity = availableQuantities[selectedProduct.value] ?? 0; // Use 0 as fallback if undefined
    const updatedAvailableQuantity = currentAvailableQuantity - parseInt(quantitySold, 10);

    const newProduct = {
        uniqueId,
        productId: selectedProduct.value,
        productName: selectedProduct.label,
        color: color,
        quantitySold: parseInt(quantitySold, 10),
        saleAmount: parseFloat(saleAmount),
        avgPrice: avgPrice, 
        availableQuantity: updatedAvailableQuantity, // Update this dynamically
    };

    // Add the new product to the products array
    setProducts((prevProducts) => [...prevProducts, newProduct]);

    return { selectedProduct, quantitySold: newProduct.quantitySold, saleAmount: newProduct.saleAmount };
};
