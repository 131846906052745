import React from 'react'

const ReportCard = ({title, value}) => {
    return (
        <>
            <div className='col-lg-4 col-md-6 col-sm-12 pt-4'>
                <div className="card cardWidth" >
                    <div className="card-body">
                        <h3 className="">{title}</h3>
                        <p className="card-text pt-3">{value}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReportCard