import React, { useEffect, useState } from 'react';
import "../Purchase/Purchase.css";
import { useCustomFormik } from '../../configs/formik/formikConfig';
import { addCustomerValidationSchema } from '../../configs/formik/validationSchema/addCustomerValidation';
import { handleAlphanumericKeyDown, handleDigitAndDecimalKeyDown } from '../../utils/handlers/keyDownHandlers';
import { AddCustomer } from './services/addCustomerServices';
import ConfirmationModal from '../Common/modal/ConfirmationModal';

const AddCustomerForm = () => {
  const [showDueDate, setShowDueDate] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);  // Modal state
  const formik = useCustomFormik({
    customerName: '',
    customerPhone: '',
    address: '',
    creditBalance: '',
    dueDate: ''
  }, addCustomerValidationSchema, async (values, { resetForm }) => {
    // console.log('Form Data Submitted:', values);

    // calling APi to add customer
    await AddCustomer(values)
    resetForm();
  });

  useEffect(() => {
    setShowDueDate(formik.values.creditBalance !== '');
  }, [formik.values.creditBalance]);

  const handleAddClick = async (e) => {
    e.preventDefault();

    // Validate the form fields
    const errors = await formik.validateForm();

    // Check if customerName, customerPhone, and address fields are valid
    if (!errors.customerName && !errors.customerPhone && !errors.address) {
      setModalOpen(true); // Open the modal if all fields are valid
    } else {
      formik.setTouched({
        customerName: true,
        customerPhone: true,
        address: true
      });
    }
  };


  const toggleModal = () => setModalOpen(!isModalOpen);

  const handleConfirm = () => {
    formik.handleSubmit();  // Call form submission when modal is confirmed
    setModalOpen(false);
  };


  return (
    <>
      <div className="contactUsFormContainer mt-3">
        <form className='contactUsForm pb-4' autoComplete="off">
          <div className='row'>
            <div className='col-12 col-lg-6'>
              <label htmlFor='customerName'>Name</label>
              <input
                type='text'
                id='customerName'
                autoComplete={'off'}
                onChange={formik.handleChange}
                value={formik.values.customerName}
                placeholder="Type here"
                onKeyDown={handleAlphanumericKeyDown}
                maxLength={50}
                className={`${formik.touched.customerName && formik.errors.customerName ? 'error-input' : ''}`}
                onBlur={formik.handleBlur}
              />
              {formik.touched.customerName && formik.errors.customerName && (
                <div className="error">{formik.errors.customerName}</div>
              )}
            </div>
            <div className='col-12 col-lg-6'>
              <label htmlFor='customerPhone'>Phone Number</label>
              <input
                type="text"
                id="customerPhone"
                name="customerPhone"
                autoComplete="off"
                value={formik.values.customerPhone}
                placeholder="Type here"
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d{0,11}$/.test(value)) {
                    formik.handleChange(e);
                  }
                }}
                className={`${formik.touched.customerPhone && formik.errors.customerPhone ? 'error-input' : ''}`}
                onBlur={formik.handleBlur}
              />
              {formik.touched.customerPhone && formik.errors.customerPhone && (
                <div className="error">{formik.errors.customerPhone}</div>
              )}
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-lg-6'>
              <label htmlFor='address'>Address</label>
              <input
                type="text"
                id="address"
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
                required
                autoComplete="off"
                placeholder="Type here"
                maxLength={200}
                className={`${formik.touched.address && formik.errors.address ? 'error-input' : ''}`}
                onBlur={formik.handleBlur}
              />
              {formik.touched.address && formik.errors.address && (
                <div className="error">{formik.errors.address}</div>
              )}
            </div>
            <div className='col-12 col-lg-6'>
              <label htmlFor='creditBalance'>Credit Balance</label>
              <input
                type="text"
                id="creditBalance"
                name="creditBalance"
                value={formik.values.creditBalance}
                onChange={formik.handleChange}
                autoComplete="off"
                placeholder="0.0"
                onKeyDown={handleDigitAndDecimalKeyDown}
                className={`${formik.touched.creditBalance && formik.errors.creditBalance ? 'error-input' : ''}`}
                onBlur={formik.handleBlur}
              />
              {formik.touched.creditBalance && formik.errors.creditBalance && (
                <div className="error">{formik.errors.creditBalance}</div>
              )}
            </div>
          </div>
          {showDueDate && <div className='row'>
            <div className='col-12 col-lg-6'>
              <label htmlFor='dueDate'>Due Date</label>
              <input
                type="date"
                id="dueDate"
                name="dueDate"
                value={formik.values.dueDate}
                onChange={formik.handleChange}
                className={`${formik.touched.dueDate && formik.errors.dueDate ? 'error-input' : ''}`}
                onBlur={formik.handleBlur}
              />
              {formik.touched.dueDate && formik.errors.dueDate && (
                <div className="error">{formik.errors.dueDate}</div>
              )}
            </div>
          </div>}

          <div className='text-center text-lg-end mt-3'>
            <button type="button" className="btn btn-secondary me-2" onClick={handleAddClick}>Add</button>
            <button className='btn btn-secondary' type="reset" onClick={formik.handleReset}>Reset</button>
          </div>
        </form>
      </div>

      {/* Reusable Modal */}
      <ConfirmationModal
        isOpen={isModalOpen}
        toggle={toggleModal}
        onConfirm={handleConfirm}   // Submit form on confirm
        message="Are you sure you want to add this customer?"
      />
    </>
  );
}

export default AddCustomerForm;
