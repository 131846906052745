import React from 'react'
import AddCustomerForm from '../Components/Customer/AddCustomerForm'

const AddCustomer = () => {
  return (
    <>
      <div className="mt-2 pt-1">
        <h1 >Add Customer</h1>
        <AddCustomerForm />
      </div>
    </>
  )
}

export default AddCustomer