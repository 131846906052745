/**
 * Calculate the total amount based on the saleAmount of each product
 * @param {Array} products - Array of products, each with a saleAmount
 * @return {number} - The total sale amount
 */
export const calculateTotalAmount = (products) => {
    return products?.reduce(
        (acc, product) => acc + parseFloat(product.saleAmount || 0),
        0
    );
};
