import axios from "axios"
import { REPORT_API_END_POINT } from "../../../utils/constants/apiUrls"
import { showToast } from "../../../utils/handlers/toastHandler"


export const getReports = async (startDate, endDate) => {
    try {

        const result = await axios.get(`${REPORT_API_END_POINT}/getReports?startDate=${startDate}&endDate=${endDate}`, {
            withCredentials: true
        })
        // console.log(result.data)
        if (result.data.success) {
            showToast(result.status, result.data.message)
        }
        return result.data

    } catch (error) {
        // console.log(error)
        if (error.response) {
            const { status, data } = error.response

            showToast(status, data.message)

        } else {
            showToast(500, "Some error occured")
        }
    }
}

export const getAllCreditRemaining = async () => {
    try {

        const result = await axios.get(`${REPORT_API_END_POINT}/getAllCreditRemaining`, {
            withCredentials: true
        })
        // console.log(result.data)
       
        return result.data

    } catch (error) {
        console.log(error)
       
    }
}
