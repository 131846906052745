import React from 'react'
import SalesForm from '../Components/Sales/SalesForm'

const Sales = () => {
  return (
    <>
      <div className=" mt-2 pt-1">
        <h1 >Sales</h1>
        <SalesForm />
      </div>
    </>
  )
}

export default Sales