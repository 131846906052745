// const URL = process.env.REACT_APP_BACKEND_URL;
// const URL = "http://customer1.localhost:3000";
// const URL = "http://customer2.localhost:3000";
const URL = "https://pos-be.techconsultivo.email";
export const USER_API_END_POINT = `${URL}/api/v1/user`;
export const PURCHASE_API_END_POINT = `${URL}/api/v1/purchase`
export const COLOR_API_END_POINT = `${URL}/api/v1/color`
export const EXPENSE_API_END_POINT = `${URL}/api/v1/expense`
export const CUSTOMER_API_END_POINT = `${URL}/api/v1/customer`
export const INVENTORY_API_END_POINT = `${URL}/api/v1/inventory`
export const CREDIT_API_END_POINT = `${URL}/api/v1/credit`
export const REPORT_API_END_POINT = `${URL}/api/v1/report`
export const SALE_API_END_POINT = `${URL}/api/v1/sale`
