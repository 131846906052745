// services/customerService.js
import axios from 'axios';
import { CUSTOMER_API_END_POINT } from '../../../utils/constants/apiUrls';


export const fetchAllCustomers = async () => {
    try {
        const response = await axios.get(`${CUSTOMER_API_END_POINT}/getAllCustomers`, {
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching customers');
    }
};
