import { useCallback, useState } from 'react';
import { searchProduct } from '../services/purchaseApi';
import { showToast } from '../../../utils/handlers/toastHandler';


export const useProductSearch = (formik) => {
    const [productOptions, setProductOptions] = useState([]);
    const [searchTimeout, setSearchTimeout] = useState(null);

    const handleProductInputChange = useCallback((inputValue, { action }) => {
        if (action === 'input-change') {
            formik.setFieldValue('productName', inputValue);

            // Clear previous timeout
            if (searchTimeout) clearTimeout(searchTimeout);

            // Set new timeout for debounce
            setSearchTimeout(setTimeout(async () => {
                if (inputValue) {
                    try {
                        const response = await searchProduct(inputValue);
                        if (response.data) {
                            const products = response.data.map(product => ({
                                value: product.product_id,
                                label: product.product_name,
                            }));
                            setProductOptions(products);
                        }
                    } catch (error) {
                        showToast(500, 'Error fetching products');
                    }
                }
            }, 1000)); // 1 second debounce
        }
    }, [searchTimeout, formik]);

    const handleProductChange = (selectedOption) => {
        formik.setFieldValue('productName', selectedOption ? selectedOption.label : '');
    };

    return { productOptions, handleProductInputChange, handleProductChange };
};
