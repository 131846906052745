import axios from "axios";
import { CUSTOMER_API_END_POINT } from "../../../utils/constants/apiUrls";
import { showToast } from "../../../utils/handlers/toastHandler";

export const AddCustomer = async (values) => {
    try {
        const result = await axios.post(`${CUSTOMER_API_END_POINT}/addCustomer`, values, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        })


        // console.log(result)
        if (result.data.success) {
            showToast(result.status, result.data.message)
        }

    } catch (error) {
        // console.log(error)
        if (error.response) {
            const { status, data } = error.response

            showToast(status, data.message)

        } else {

            showToast(500, "Some error occured")
        }
    }
}