/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import "../Purchase/Purchase.css";
import { customStyles } from './CustomStyleSelect';
import { useCustomFormik } from '../../configs/formik/formikConfig';
import { purchaseValidationSchema } from '../../configs/formik/validationSchema/purchaseValidation';
import { handleAlphanumericKeyDown, handleDigitAndDecimalKeyDown, handleDigitsOnlyKeyDown } from '../../utils/handlers/keyDownHandlers';
import CustomDropdownIndicator from '../Common/customDropDownIndcator';
import { useProductSearch } from './hooks/useProductSearch';
import { useFetchColors } from './hooks/useFetchColors';
import { addProduct } from './services/purchaseApi';
import ConfirmationModal from '../Common/modal/ConfirmationModal';

const PurchaseForm = () => {
    const [isModalOpen, setModalOpen] = useState(false);  // Modal state
    const colorsOptions = useFetchColors(); // Fetch colors
    const formik = useCustomFormik({
        productName: '',
        purchasePrice: '',
        purchaseDate: '',
        quantity: '',
        color: ''
    }, purchaseValidationSchema, async (values, { resetForm }) => {
        await addProduct(values); // Submit form data
        resetForm(); // Reset form after successful submission

        setModalOpen(false);
    });

    const { productOptions, handleProductInputChange, handleProductChange } = useProductSearch(formik);

    useEffect(() => {
        const today = new Date().toISOString().split('T')[0];
        if (!formik.values.purchaseDate) {
            formik.setFieldValue('purchaseDate', today);
        }
    }, []);  // Run only once when the component is mounted


    const handleAddClick = async (e) => {
        e.preventDefault();
        // Validate the form fields
        const errors = await formik.validateForm();
        // If all fields are valid, open the modal
        if (!errors.productName && !errors.purchasePrice && !errors.purchaseDate && !errors.quantity && !errors.color) {
            setModalOpen(true);
        } else {
            formik.setTouched({
                productName: true,
                purchasePrice: true,
                purchaseDate: true,
                quantity: true,
                color: true
            });
        }
    };

    const toggleModal = () => setModalOpen(!isModalOpen);

    const handleConfirm = () => {
        formik.handleSubmit();  // Call form submission when modal is confirmed
    };

    return (
        <>
            <div className="contactUsFormContainer mt-3">
                <form className="contactUsForm pb-4" >
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <label htmlFor="productName">Product</label>
                            <Select
                                id="productName"
                                name="productName"
                                placeholder="Select product"
                                options={productOptions}
                                onKeyDown={handleAlphanumericKeyDown}
                                onChange={handleProductChange}
                                onInputChange={handleProductInputChange}
                                value={formik.values.productName ? { label: formik.values.productName, value: formik.values.productName } : null}
                                styles={customStyles}
                                components={{ DropdownIndicator: CustomDropdownIndicator }}
                                onBlur={formik.handleBlur}
                                isSearchable
                                error={formik.touched.productName && formik.errors.productName}
                        
                            />
                            {formik.touched.productName && formik.errors.productName && (
                                <div className="error">{formik.errors.productName}</div>
                            )}
                        </div>

                        <div className="col-12 col-lg-6">
                            <label htmlFor="purchasePrice">Price</label>
                            <input
                                type="text"
                                id="purchasePrice"
                                name="purchasePrice"
                                placeholder="0.0"
                                value={formik.values.purchasePrice}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                onKeyDown={handleDigitAndDecimalKeyDown}
                                className={formik.touched.purchasePrice && formik.errors.purchasePrice ? 'error-input' : ''}
                            />
                            {formik.touched.purchasePrice && formik.errors.purchasePrice && (
                                <div className="error">{formik.errors.purchasePrice}</div>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <label htmlFor="purchaseDate">Date</label>
                            <input
                                type="date"
                                id="purchaseDate"
                                name="purchaseDate"
                                value={formik.values.purchaseDate}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={formik.touched.purchaseDate && formik.errors.purchaseDate ? 'error-input' : ''}
                            />
                            {formik.touched.purchaseDate && formik.errors.purchaseDate && (
                                <div className="error">{formik.errors.purchaseDate}</div>
                            )}
                        </div>

                        <div className="col-12 col-lg-6">
                            <label htmlFor="quantity">Quantity</label>
                            <input
                                type="number"
                                id="quantity"
                                name="quantity"
                                placeholder='0.0'
                                value={formik.values.quantity}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                onKeyDown={handleDigitsOnlyKeyDown}
                                className={formik.touched.quantity && formik.errors.quantity ? 'error-input' : ''}
                            />
                            {formik.touched.quantity && formik.errors.quantity && (
                                <div className="error">{formik.errors.quantity}</div>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <label htmlFor="color">Color</label>
                            <select
                                id="color"
                                name="color"
                                value={formik.values.color}
                                onChange={formik.handleChange}
                                className={formik.touched.color && formik.errors.color ? 'error-input' : ''}
                                onBlur={formik.handleBlur}
                            >
                                <option value="" disabled>Select Color</option>
                                {colorsOptions.map(color => (
                                    <option key={color.value} value={color.value}>
                                        {color.label}
                                    </option>
                                ))}
                            </select>
                            {formik.touched.color && formik.errors.color && (
                                <div className="error">{formik.errors.color}</div>
                            )}
                        </div>
                    </div>

                    <div className="text-center mt-lg-0 mt-4 text-lg-end">
                        <button type="button" className="btn btn-secondary me-2" onClick={handleAddClick}>Add</button>
                        <button className="btn btn-secondary" type="reset" onClick={formik.handleReset}>Reset</button>
                    </div>
                </form>
            </div>
            {/* Reusable Modal */}
            <ConfirmationModal
                isOpen={isModalOpen}
                toggle={toggleModal}
                onConfirm={handleConfirm}   // Submit form on confirm
                message="Are you sure you want to add this product?"
            />
        </>
    );
};

export default PurchaseForm;
