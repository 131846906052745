import React, { useCallback, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import "../Sales/Sale.css"
import { showToast } from '../../utils/handlers/toastHandler';
import { handleAlphabetsKeyDown } from '../../utils/handlers/keyDownHandlers';
import { fetchAllCustomers } from './services/getAllCustomer';
import ButtonComponent from './helper/ButtonComponent';

const AllCustomersGrid = () => {
    const gridRef = useRef();
    const [filterText, setFilterText] = useState('');
    const [rowData, setRowData] = useState([]);

    const defaultColDef = {
        flex: 1, // Allow columns to take up the remaining space
        minWidth: 150, // Ensure columns have a minimum width
        sortable: false,
        resizable: false,
        suppressMovable: true,
    };

    const columnDefs = [
        { headerName: "Customer name", field: 'customer_name' },
        { headerName: "Phone no", field: 'customer_phone' },
        {
            headerName: "Credit Balance", field: 'credit_balance',
            valueFormatter: (params) => {
                const balance = params.value;
                // Check if the balance is null or undefined
                if (balance === null || balance === undefined) {
                    return 'Rs 0.00'; // Placeholder if balance is missing
                }
                return `Rs ${parseFloat(balance).toFixed(2)}`; // Format balance to 2 decimal places
            }
        },
        {
            headerName: "Due date",
            field: 'due_date',
            valueFormatter: (params) => {
                const dateValue = params.value;
                // Check if the due_date is null, undefined, or an empty string
                if (!dateValue) {
                    return '-'; // Return a placeholder if no date is available
                }
                const date = new Date(dateValue);
                // Check if the date is valid
                if (isNaN(date.getTime())) {
                    return 'Invalid Date'; // Handle invalid dates
                }
                return date.toLocaleDateString(); // Return the formatted date
            },
        },
        {
            field: "Status",
            headerName: "Action",
            cellRenderer: (params) => (
                <ButtonComponent customerName={params?.data.customer_name} />
            ),

        }
    ];

    // Fetch customer data
    const onGridReady = useCallback(async (params) => {
        try {
            const data = await fetchAllCustomers();
            if (data) {
                setRowData(data);
            }
        } catch (error) {
            showToast(500, 'Some error occurred');
        }
    }, []);

    // Filtering logic
    const doesExternalFilterPass = useCallback((node) => {
        return node.data.customer_name.toLowerCase().includes(filterText.toLowerCase());
    }, [filterText]);

    const isExternalFilterPresent = useCallback(() => filterText !== '', [filterText]);

    const onFilterTextBoxChanged = useCallback(() => {
        const filterValue = document.getElementById("filter-text-box").value;
        setFilterText(filterValue);
        gridRef.current.api.onFilterChanged();
    }, []);

    return (
        <>
            <div className="contactUsFormContainer ">
                <div className='contactUsForm pb-4'>
                    <div className='row'>
                        <div className='col-12 col-lg-6'>
                            <label htmlFor='customerName'>Customer</label>
                            <input
                                type='text'
                                id='filter-text-box'
                                name='customerName'
                                placeholder="Search customer"
                                onKeyDown={handleAlphabetsKeyDown}
                                onInput={onFilterTextBoxChanged}
                                maxLength={50}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="ag-theme-quartz mt-4 " style={{ height: 390 }}>
                <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    onGridReady={onGridReady}
                    doesExternalFilterPass={doesExternalFilterPass}
                    isExternalFilterPresent={isExternalFilterPresent}
                    pagination={true}
                    paginationPageSizeSelector={false}
                    paginationPageSize={10}
                    alwaysShowHorizontalScroll={true}
                />
            </div>
        </>
    )
}

export default AllCustomersGrid