import axios from "axios"
import { showToast } from "../../../utils/handlers/toastHandler"
import { SALE_API_END_POINT } from "../../../utils/constants/apiUrls"

export const addSale = async (values) => {

    // console.log(values)
    try {

        const result = await axios.post(`${SALE_API_END_POINT}/addSale`, values, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        })

        // console.log(result)
        if (result.data.success) {
            showToast(result.status, result.data.message)
        }

    } catch (error) {
        // console.log(error)
        if (error.response) {
            const { status, data } = error.response
            showToast(status, data.message)

        } else {
            showToast(500, "Some error occured")
        }
    }
}

