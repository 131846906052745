import * as Yup from 'yup';

export const signupValidationSchema = Yup.object({
    name: Yup.string()
        .matches(/^[a-zA-Z\s]+$/, "Name must contain alphabets only")
        .max(100, "Name must be at most 100 characters")
        .min(3, "Name must be at least 3 characters")
        .required('Name is required'),
    username: Yup.string()
        // .matches(/^[a-zA-Z0-9\s]+$/, "Username must contain letters and numbers only")
        .max(100, "Username must be at most 100 characters")
        .min(3, "Username must be at least 3 characters")
        .required('Username is required'),
    email: Yup.string()
        .email("Please enter a valid email address")
        .required('Email is required'),
    password: Yup.string()
        .max(50, 'Password must be at most 50 characters')
        .min(8, 'Password must be at least 8 characters')
        .required('Password is required'),
});