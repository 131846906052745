import './App.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Toaster } from 'sonner';
import Expense from './Pages/Expense';
import Sales from './Pages/Sales';
import SignUp from './Components/User/SignUp';
import LogIn from './Components/User/LogIn';
import Customer from './Pages/Customer';
import AddCustomer from './Pages/AddCustomer';
import CreditReceive from './Pages/CreditReceive';
import CreditHistory from './Pages/CreditHistory';
import Inventory from './Pages/Inventory';
import Report from './Pages/Report';
import MainComponent from './Components/Common/MainComponent';
import Purchase from './Pages/Purchase';

function App() {
  return (
    <>
      <Toaster position="bottom-right" />
      <Router>
        <Routes>
          <Route path='/' element={<LogIn />} />
          <Route path='/purchase' element={<MainComponent children={<Purchase />} />} />
          <Route path='/expense' element={<MainComponent children={<Expense />} />} />
          <Route path='/signup' element={<SignUp />} />
          <Route path='/login' element={<LogIn />} />
          <Route path='/sale' element={<MainComponent children={<Sales />} />} />
          <Route path='/customer' element={<MainComponent children={<Customer />} />} />
          <Route path='/addcustomer' element={<MainComponent children={<AddCustomer />} />} />
          <Route path='/creditreceive' element={<MainComponent children={<CreditReceive />} />} />
          <Route path='/credithistory' element={<MainComponent children={<CreditHistory />} />} />
          <Route path='/inventory' element={<MainComponent children={<Inventory />} />} />
          <Route path='/reports' element={<MainComponent children={<Report />} />} />
        </Routes>
      </Router>

    </>
  );
}

export default App;
