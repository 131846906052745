import axios from "axios";
import { showToast } from "../../../utils/handlers/toastHandler";
import { EXPENSE_API_END_POINT } from "../../../utils/constants/apiUrls";

export const addExpense = async (values) => {
    try {

        const result = await axios.post(`${EXPENSE_API_END_POINT}/addExpense`, values, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        })

        // console.log(result)
        if (result.data.success) {
            showToast(result.status, result.data.message)
        }

    } catch (error) {
        if (error.response) {
            const { status, data } = error.response

            showToast(status, data.message)

        } else {
            showToast(500, "No response from the server")
        }
    }
};
