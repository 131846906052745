import * as Yup from 'yup';

export const loginValidationSchema = Yup.object({
    email: Yup.string()
        .email("Please enter a valid email address")
        .required('Email is required'),
    password: Yup.string()
        .max(100, 'Password must be at most 100 characters')
        .min(3, 'Password must be at least 3 characters')
        .required('Password is required'),
});