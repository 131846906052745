import { toast } from 'sonner'

export const showToast = (status, message) => {
    switch (status) {
        case 200:
            toast.success(message)
            break;
        case 201:
            toast.info(message)
            break;
        case 400:
            toast.error(message)
            break;
        case 500:
            toast.error(message)
            break;
        default:
            toast(message)
    }
}