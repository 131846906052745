import * as Yup from 'yup';

export const saleValidationSchema =(totalAmount)=> Yup.object({
    customerName: Yup.string()
        .max(50, "Customer name must be at most 100 characters")
        .min(3, "Customer name must be at least 3 characters")
        .required('Customer name is required'),
    customerPhone: Yup.string()
        .typeError('Phone number must be of format (03071234567)')
        .matches(/^\d{11}$/, 'Phone number must be exactly 11 digits')
        .required('Phone number is required'),
    saleType: Yup.string()
        .required('Sale type is required'),
    dueDate: Yup.date()
        .when('saleType', {
            is: 'Credit',
            then: (d) => d.required('Due date is required'),
            otherwise: (d) => d.nullable()
        }),
    advanceAmount: Yup.number()
        .min(1, "Advance amount must be at least Rs 1")
        .max(totalAmount, `Advance amount cannot exceed total amount of Rs ${totalAmount}`),
})


