import * as Yup from 'yup';

export const addCustomerValidationSchema = Yup.object({

    customerName: Yup.string()
        .max(50, "Customer name must be at most 50 characters")
        .min(3, "Customer name must be at least 3 characters")
        .required('Customer name is required'),
    customerPhone: Yup.string()
        .matches(/^\d{11}$/, 'Phone number must be exactly 11 digits')
        .required('Phone number is required')
        .typeError('Phone number must be of format (03071234567)'),
    address: Yup.string()
        .max(200, "Address must be at most 100 characters")
        .required('Address is required'),
    creditBalance: Yup.number()
        .min(1, "Credit balance must be at least Rs 1")
        .typeError('Credit Balance must be a valid number'),
    dueDate: Yup.date()
        .when('creditBalance', {
            is: (val) => val && val !== '',
            then: (d) => d.required('Due date is required'),
            otherwise: (d) => d.nullable()
        })
})