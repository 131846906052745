import React from 'react'
import ExpenseForm from '../Components/Expense/ExpenseForm'

const Expense = () => {
  return (
    <>
      <div className="mt-2 pt-1">
        <h1 >Expense</h1>
        <ExpenseForm />
      </div>
    </>
  )
}

export default Expense