import React from 'react'
import AllCustomersGrid from '../Components/Customer/AllCustomersGrid'
import { NavLink } from "react-router-dom";

const Customer = () => {
  return (
    <>
      {/* <div className="mt-2 pt-1">
        <h1 >All Customers</h1>
        <div className='mt-3'>
          <AllCustomersGrid />
        </div>
      </div> */}

      <div className="row mt-2 pt-1">
        <div className='d-flex justify-content-between'>
          <div >
            <h1 >All Customers</h1>
          </div>
          <div>
            <NavLink to="/addcustomer">
              <button className='btn btn-dark mt-1' >Add</button>
            </NavLink>
          </div>
        </div>
      </div>
      <div className='mt-3'>
        <AllCustomersGrid />
      </div>

    </>
  )
}

export default Customer