import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import "../../Purchase/Purchase.css";
import { customStyles } from '../../Purchase/CustomStyleSelect';
import CustomDropdownIndicator from '../../Common/customDropDownIndcator';
import { handleAlphanumericKeyDown, handleDigitAndDecimalKeyDown } from '../../../utils/handlers/keyDownHandlers';
import { useCustomFormik } from '../../../configs/formik/formikConfig';
import { creditReceiveValidationSchema } from '../../../configs/formik/validationSchema/creditReceiveValidation';
import { handleCustomerInputChange } from '../../../utils/handlers/inputChangeHandlers';
import { Input } from '../../Common/SelectInputLength';
import { getCustomerBalance, submitCreditReceive } from '../services/creditServices';

const CreditReceiveForm = () => {
  const [showRemainingDate, setShowRemainingDate] = useState(false);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [creditBalance, setCreditBalance] = useState(0); // Store the fetched balance

  const formik = useCustomFormik({
    creditReceive: '',
    receiveDate: '',
    remainingDate: '',
    creditRemaining: '' // Add the field to formik
  }, creditReceiveValidationSchema, async (values) => {

    // submit values excluding credit remaining
    const { creditRemaining, ...submitValues } = values;
    // console.log('Form Data Submitted:', submitValues);
    const result = await submitCreditReceive(submitValues);  // Wait for the submission

    if (result && result.success) {
      // Reset form state and component state after successful submission
      formik.resetForm();
      setSelectedCustomer(null);
      setCreditBalance(0);
      setShowRemainingDate(false);
    }

  });

  useEffect(() => {
    const remainingBalance = creditBalance - formik.values.creditReceive
    // console.log(creditBalance)
    // console.log(formik.values.creditReceive)
    // console.log(remainingBalance)
    setShowRemainingDate(remainingBalance > 0);
  }, [formik.values.creditReceive, creditBalance]);

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    formik.setFieldValue("receiveDate", today);
    // eslint-disable-next-line 
  }, []);

  const handleCustomerChange = async (selectedOption) => {
    setSelectedCustomer(selectedOption.label.split(" (")[0]);
    const balance = await getCustomerBalance({ customerName: selectedOption.label.split(" (")[0] });
    setCreditBalance(balance); // Set the fetched balance to state
    formik.setFieldValue("creditRemaining", balance); // Set the balance in formik's state
  };

  return (
    <div className="contactUsFormContainer mt-3">
      <div className='contactUsForm pb-4 pt-4'>
        <div className='row'>
          <div className='col-12 col-lg-6'>
            <label htmlFor='customerName'>Customer Name</label>
            <Select
              id='customerName'
              placeholder="Select Customer"
              options={customerOptions}
              onInputChange={handleCustomerInputChange(setCustomerOptions)}
              onChange={handleCustomerChange}
              value={selectedCustomer}
              styles={customStyles}
              components={{ DropdownIndicator: CustomDropdownIndicator, Input }}
              noOptionsMessage={() => 'Customer does not exist'}
              isSearchable
              className={`mb-2`}
              onKeyDown={handleAlphanumericKeyDown}
            />
          </div>

          <div className='col-12 col-lg-6'>
            <label htmlFor='creditRemaining'>Remaining</label>
            <input
              type="text"
              id="creditRemaining"
              name="creditRemaining"
              placeholder='0.0'
              value={formik.values.creditRemaining} // Use formik's value
              readOnly // Make the field read-only
              onKeyDown={handleDigitAndDecimalKeyDown}
            />
          </div>
        </div>

        {creditBalance > 0 && (
          <form className='' onSubmit={formik.handleSubmit}>
            <div className='row'>
              <div className='col-12 col-lg-6'>
                <label htmlFor='creditReceive'>Received</label>
                <input
                  type="text"
                  id="creditReceive"
                  name="creditReceive"
                  placeholder='0.0'
                  value={formik.values.creditReceive}
                  onChange={formik.handleChange}
                  className={`${formik.touched.creditReceive && formik.errors.creditReceive ? 'error-input' : ''}`}
                  onBlur={formik.handleBlur}
                  onKeyDown={handleDigitAndDecimalKeyDown}
                />
                {formik.touched.creditReceive && formik.errors.creditReceive && (
                  <div className="error">{formik.errors.creditReceive}</div>
                )}
              </div>

              <div className='col-12 col-lg-6'>
                <label htmlFor='receiveDate'>Received Date</label>
                <input
                  type="date"
                  id="receiveDate"
                  name="receiveDate"
                  value={formik.values.receiveDate}
                  onChange={formik.handleChange}
                  className={`${formik.touched.receiveDate && formik.errors.receiveDate ? 'error-input' : ''}`}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.receiveDate && formik.errors.receiveDate && (
                  <div className="error">{formik.errors.receiveDate}</div>
                )}
              </div>
            </div>

            {showRemainingDate && (
              <div className='row'>
                <div className='col-12 col-lg-6'>
                  <label htmlFor='remainingDate'>Remaining Due Date</label>
                  <input
                    type="date"
                    id="remainingDate"
                    name="remainingDate"
                    value={formik.values.remainingDate}
                    onChange={formik.handleChange}
                    className={`${formik.touched.remainingDate && formik.errors.remainingDate ? 'error-input' : ''}`}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.remainingDate && formik.errors.remainingDate && (
                    <div className="error">{formik.errors.remainingDate}</div>
                  )}
                </div>
              </div>
            )}

            <div className='text-center text-lg-end mt-3'>
              <button className='btn btn-secondary requestDemoButton me-2' type="submit">Add</button>
              <button className='btn btn-secondary requestDemoButton' type="reset" onClick={formik.handleReset}>Reset</button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default CreditReceiveForm;
