import { useEffect, useState } from 'react';
import { allColors } from '../../Common/services/colorServices';
import { showToast } from '../../../utils/handlers/toastHandler';


export const useFetchColors = () => {
    const [colorsOptions, setColorsOptions] = useState([]);

    useEffect(() => {
        const fetchColors = async () => {
            try {
                const colorsData = await allColors();
                if (colorsData) {
                    const options = colorsData.map(color => ({
                        value: color.color_name,
                        label: color.color_name,
                    }));
                    setColorsOptions(options);
                }
            } catch (error) {
                showToast(500, 'Error fetching colors');
            }
        };

        fetchColors();
    }, []);

    return colorsOptions;
};
