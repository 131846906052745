import React, { useEffect, useState } from 'react';
import "../Purchase/Purchase.css";
import { expenseValidationSchema } from '../../configs/formik/validationSchema/expenseValidation';
import { handleDigitAndDecimalKeyDown } from '../../utils/handlers/keyDownHandlers';
import { useCustomFormik } from '../../configs/formik/formikConfig';
import { addExpense } from './api/expenseApi';
import ConfirmationModal from '../Common/modal/ConfirmationModal';

const ExpenseForm = () => {
  const [isModalOpen, setModalOpen] = useState(false);  // Modal state
  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    formik.setFieldValue("expenseDate", today);

    // eslint-disable-next-line
  }, []);

  const formik = useCustomFormik({
    amount: '',
    expenseDate: '',
    description: ''
  }, expenseValidationSchema, async (values, { resetForm }) => {

    await addExpense(values)
    resetForm();
  })

  const handleAddClick = async (e) => {
    e.preventDefault();

    // Validate the form fields
    const errors = await formik.validateForm();

    // If all fields are valid, open the modal
    if (!errors.amount && !errors.expenseDate && !errors.description) {
      setModalOpen(true);
    } else {
      formik.setTouched({
        amount: true,
        expenseDate: true,
        description: true
      });
    }
  };

  const toggleModal = () => setModalOpen(!isModalOpen);

  const handleConfirm = () => {
    formik.handleSubmit();  // Call form submission when modal is confirmed
    setModalOpen(false);
  };

  return (
    <>
      <div className="contactUsFormContainer mt-3">
        <form className='contactUsForm pb-4' >
          <div className='row'>
            <div className='col-12 col-lg-6'>
              <label htmlFor='amount'>Amount</label>
              <input
                type="text"
                id="amount"
                name="amount"
                value={formik.values.amount}
                onChange={formik.handleChange}
                placeholder='0.0'
                className={` ${formik.touched.amount && formik.errors.amount ? 'error-input' : ''}`}
                onBlur={formik.handleBlur}
                onKeyDown={handleDigitAndDecimalKeyDown}
              />
              {formik.touched.amount && formik.errors.amount && (
                <div className="error">{formik.errors.amount}</div>
              )}
            </div>
            <div className=' col-12 col-lg-6'>
              <label htmlFor='expenseDate'>Date</label>
              <input
                type="date"
                id="expenseDate"
                name="expenseDate"
                value={formik.values.expenseDate}
                onChange={formik.handleChange}
                placeholder='Expense date'
                className={` ${formik.touched.expenseDate && formik.errors.expenseDate ? 'error-input' : ''}`}
                onBlur={formik.handleBlur}
              />
              {formik.touched.expenseDate && formik.errors.expenseDate && (
                <div className="error">{formik.errors.expenseDate}</div>
              )}
            </div>
          </div>
          <div className='row'>
            <div className=' col-12 col-lg-6'>
              <label htmlFor='description'>Description</label>
              <textarea
                id="description"
                name="description"
                maxLength="200"
                placeholder='Type here'
                value={formik.values.description}
                onChange={formik.handleChange}
                className={` ${formik.touched.description && formik.errors.description ? 'error-input' : ''}`}
                onBlur={formik.handleBlur}

              />
              {formik.touched.description && formik.errors.description && (
                <div className="error">{formik.errors.description}</div>
              )}
            </div>
            <div className=' col-12 col-lg-6'>

            </div>
          </div>

          <div className='text-center mt-lg-0 mt-4 text-lg-end'>
            <button type="button" className="btn btn-secondary me-2" onClick={handleAddClick}>Add</button>
            <button className='btn btn-secondary' type="reset" onClick={formik.handleReset}>Reset</button>
          </div>
        </form>
      </div>

      {/* Reusable Modal */}
      <ConfirmationModal
        isOpen={isModalOpen}
        toggle={toggleModal}
        onConfirm={handleConfirm}   // Submit form on confirm
        message="Are you sure you want to add this expense?"
      />
    </>
  )
}

export default ExpenseForm