// utils/handlers/inputChangeHandlers.js
import axios from 'axios';
import { CUSTOMER_API_END_POINT, PURCHASE_API_END_POINT } from '../constants/apiUrls';
import { showToast } from './toastHandler';


// for Sale form only
export const handleProductChange = (
    setSelectedProduct,
    setAvailableQuantity,
    setColor,
    setAvgPrice,
    products // Pass products array here
) => async (selectedOption) => {
    const productId = selectedOption ? selectedOption.value : "";
    setSelectedProduct({ label: selectedOption?.label.split(",")[0], value: selectedOption.value });

    const existingProduct = products.find(product => product.productId === productId);
    // console.log(existingProduct);
    if (existingProduct) {
        // If product already exists, use its available quantity
        setAvailableQuantity(existingProduct.availableQuantity);
        setColor(existingProduct.color);
        setAvgPrice(existingProduct.avgPrice);
    } else {
        // Fetch product details from API if not in products array
        try {
            if (productId) {
                const response = await axios.get(
                    `${PURCHASE_API_END_POINT}/searchProductById?productId=${productId}`,
                    { withCredentials: true }
                );

                const selectedProduct = response.data[0];
                setAvailableQuantity(selectedProduct.quantity);
                setColor(selectedProduct.color);
                setAvgPrice(selectedProduct.average_price);
            } else {
                setAvailableQuantity(null);
                setColor("");
                setAvgPrice("");
            }
        } catch (error) {
            console.error("Error fetching product details:", error);
        }
    }
};


let productDebounceTimer;
export const handleProductInputChange = (setProductOptions) => (inputValue, { action }) => {
    if (action === 'input-change') {
        clearTimeout(productDebounceTimer);
        productDebounceTimer = setTimeout(async () => {
            let response;
            try {
                if (inputValue.trim() !== "") {
                    response = await axios.get(`${PURCHASE_API_END_POINT}/searchProductForSale?productName=${inputValue}`, {
                        withCredentials: true
                    });
                    // console.log(response)
                    const options = response.data.map(product => ({
                        label: `${product.product_name}, Color: ${product.color}`,
                        value: product.product_id,
                    }));
                    // console.log(options)
                    setProductOptions(options);
                } else {
                    setProductOptions([]);  // Clear options if input is empty
                }
            } catch (error) {
                if (error.response) {
                    const { status, data } = error.response

                    showToast(status, data.message)

                } else {
                    showToast(500, "Some error occured")
                }
            }
        }, 1000); // 1 second delay
    }
}

export const handleSaleTypeChange = (formik, setShowDueDate) => (e) => {
    formik.handleChange(e);
    setShowDueDate(e.target.value === "Credit");
};


// Debounce function to delay API calls
let debounceTimer;
export const handleCustomerInputChange = (setCustomerOptions, formik = null) => (inputValue, { action }) => {
    if (action === 'input-change') {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(async () => {
            try {
                if (inputValue.trim() !== "") {
                    const response = await axios.get(`${CUSTOMER_API_END_POINT}/searchCustomer?customerName=${inputValue}`, {
                        withCredentials: true
                    });
                    // console.log(response);
                    const options = response.data.map(customer => ({
                        label: `${customer.customer_name} (${customer.customer_phone})`,
                        value: customer.customer_id,
                        customer_phone: customer.customer_phone // Include the phone number in the option
                    }));
                    setCustomerOptions(options);

                    // Update formik value if formik is passed
                    if (formik) {
                        formik.setFieldValue("customerName", inputValue);
                    }
                } else {
                    setCustomerOptions([]);  // Clear options if input is empty
                }
            } catch (error) {
                console.error("Error fetching customers:", error);
            }
        }, 1000); // 1 second delay
    }
};


export const handleCustomerChange = (formik) => (selectedOption) => {
    if (selectedOption) {
        formik.setFieldValue("customerName", selectedOption.label.split(" (")[0]);
        formik.setFieldValue("customerPhone", selectedOption.customer_phone); // Assuming you set customer_phone in the selected option
    } else {
        formik.setFieldValue("customerName", "");
        formik.setFieldValue("customerPhone", "");
    }
};
