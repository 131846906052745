export const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: state.selectProps.error ? '#ff0000' : state.isFocused ? 'var(--global-primary-color)' : provided.borderColor,
        borderWidth: state.selectProps.error ? '0.5px' : '0.5px',
        borderRadius: state.selectProps.error ? '4px' : '4px',
        '&:hover': {
            borderColor: state.selectProps.error ? '#ff0000' : 'var(--global-primary-color)'
        },
        boxShadow: state.selectProps.error ? '0 0 5px rgba(255, 0, 0, 0.5)' : state.isFocused ? 'none' : provided.boxShadow,

    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 99 // Adjust z-index as needed
    }),
    option: (provided) => ({
        ...provided,
        '&:focus': {
            outline: 'none',
            boxShadow: 'none'
        }
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'inherit' // Ensure the text color matches the rest of the component
    })
};
