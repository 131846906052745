export const handleAlphabetsKeyDown = (e) => {
  if (
    !/^[a-zA-Z\s]*$/.test(e.key) && // Allow alphanumeric characters and space
    e.key !== 'Backspace' && // Allow backspace
    e.key !== 'Tab' && // Allow tab
    e.key !== 'ArrowLeft' && // Allow left arrow
    e.key !== 'ArrowRight' // Allow right arrow
  ) {
    e.preventDefault();
  }
}


export const handleAlphanumericKeyDown = (e) => {
  const { value } = e.target;

  // Prevent typing more than 50 characters
  if (value.length >= 50 && e.key !== 'Backspace' && e.key !== 'Tab' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
    e.preventDefault();
    return;
  }

  // Allow only alphanumeric characters and space
  if (
    !/^[a-zA-Z0-9\s]*$/.test(e.key) &&
    e.key !== 'Backspace' &&
    e.key !== 'Tab' &&
    e.key !== 'ArrowLeft' &&
    e.key !== 'ArrowRight'
  ) {
    e.preventDefault();
  }
};



export const handleDigitAndDecimalKeyDown = (e) => {
  const { value } = e.target;
  const decimalIndex = value.indexOf('.');
  const cursorPosition = e.target.selectionStart;

  // Prevent default behavior for disallowed keys
  if (
    !/^[0-9]$/.test(e.key) &&
    e.key !== 'Backspace' &&
    e.key !== 'Tab' &&
    e.key !== 'ArrowLeft' &&
    e.key !== 'ArrowRight' &&
    e.key !== '.'
  ) {
    e.preventDefault();
    return;
  }

  // Allow only one decimal point
  if (e.key === '.' && decimalIndex !== -1) {
    e.preventDefault();
    return;
  }

  // Restrict integer part to 8 digits and decimal part to 2 digits
  if (decimalIndex > -1) {
    const integerPart = value.slice(0, decimalIndex);
    const decimalPart = value.slice(decimalIndex + 1);

    if (cursorPosition <= decimalIndex && integerPart.length >= 8 && e.key !== 'Backspace') {
      e.preventDefault();
    } else if (cursorPosition > decimalIndex && decimalPart.length >= 2 && e.key !== 'Backspace') {
      e.preventDefault();
    }
  } else {
    // Restrict total digits to 8 before decimal
    if (value.length >= 8 && e.key !== 'Backspace' && e.key !== '.') {
      e.preventDefault();
    }
  }

  // Enforce maximum value (99999999.99)
  const parsedValue = parseFloat(value);
  if (parsedValue > 99999999.99) {
    e.target.value = 99999999.99; // Set to maximum value
    e.target.selectionStart = e.target.selectionEnd = value.length; // Place cursor at the end
  }
};



export const handleDigitsOnlyKeyDown = (e) => {
  const { value } = e.target;
  if (
    !/^[0-9]*$/.test(e.key) && // Allow digits only
    e.key !== 'Backspace' && // Allow backspace
    e.key !== 'Tab' && // Allow tab
    e.key !== 'ArrowLeft' && // Allow left arrow
    e.key !== 'ArrowRight' // Allow right arrow
  ) {
    e.preventDefault();
  }

  if (value.length >= 4 && e.key !== 'Backspace') {
    e.preventDefault();
  }
};
