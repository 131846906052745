import React from 'react';
import Sidebar from '../Sidebar/Sidebar'; // Assuming you have Sidebar component

const MainComponent = ({ children }) => {

    return (
        <div className="app-container">
            <Sidebar />
            <div className={`content `}>
                {children}
            </div>
        </div>
    );
};

export default MainComponent;
