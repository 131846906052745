export const handleQuantitySoldChange = (
    setQuantitySold,
    setQuantityError,
    availableQuantity,
    setRemainingAvailableQuantity
) => (e) => {
    const value = e.target.value;

    // Update the input value regardless of errors
    setQuantitySold(value);

    // Check if the entered value is a valid number
    if (!/^\d+$/.test(value) && value !== "") {
        setQuantityError("Please enter a valid number");
        setRemainingAvailableQuantity(availableQuantity); // Reset to original if invalid
    } else if (parseInt(value) > availableQuantity) {
        // Check if the entered quantity exceeds the available quantity
        setQuantityError(`Quantity cannot exceed available quantity of ${availableQuantity}`);
        setRemainingAvailableQuantity(availableQuantity); // Reset to original if exceeded
    } else {
        // No error, clear error message and calculate remaining available quantity
        setQuantityError(null);
        const remainingQuantity = availableQuantity - parseInt(value || 0, 10);
        setRemainingAvailableQuantity(remainingQuantity); // Update remaining quantity
    }
};
