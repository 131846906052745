import React, { useCallback, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { MdDeleteForever } from "react-icons/md";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "./Sale.css";

const SalesGrid = ({ products, setProducts, setAvailableQuantities }) => {

  const defaultColDef = useMemo(() => ({
    flex: 1,
    minWidth: 150,
    sortable: false,
    resizable: false,
    suppressMovable: true,
  }), []);

  const handleDeleteProduct = useCallback((uniqueId) => {
    const productToDelete = products.find(product => product.uniqueId === uniqueId);
    
    // Update central available quantities
    if (productToDelete) {
      setAvailableQuantities(prevQuantities => ({
        ...prevQuantities,
        [productToDelete.productId]: prevQuantities[productToDelete.productId] + productToDelete.quantitySold
      }));
    }

    // Remove product from grid
    const updatedProducts = products.filter(product => product.uniqueId !== uniqueId);
    setProducts(updatedProducts);
  }, [products, setProducts, setAvailableQuantities]);


  // Render delete button with icon
  const DeleteButton = ({ params }) => (
    <MdDeleteForever
      className="deleteIcon"
      style={{ cursor: 'pointer', color: 'red' }}
      onClick={() => handleDeleteProduct(params.data.uniqueId)}
    />
  );

  // Column Definitions
  const colDefs = useMemo(() => [
    { field: "productName", headerName: "Product Name" },
    { field: "color", headerName: "Color" },
    { field: "quantitySold", headerName: "Quantity Sold" },
    { field: "saleAmount", headerName: "Sale Amount", valueFormatter: (params) => `Rs ${params.value}` },
    {
      headerName: "Actions",
      cellRenderer: (params) => <DeleteButton params={params} />,
    }
  ], [handleDeleteProduct]);

  return (
    <div className="ag-theme-quartz gridHeight">
      <AgGridReact
        rowData={products}
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        getRowId={(params) => params.data.uniqueId}
      />
    </div>
  );
};

export default SalesGrid;
