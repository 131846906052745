import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import '../Purchase/Purchase.css'
import './User.css'
import axios from "axios";
import { useCustomFormik } from '../../configs/formik/formikConfig';
import { signupValidationSchema } from '../../configs/formik/validationSchema/signupValidationSchema';
import { handleAlphabetsKeyDown } from '../../utils/handlers/keyDownHandlers';
import { USER_API_END_POINT } from '../../utils/constants/apiUrls';
import { showToast } from '../../utils/handlers/toastHandler';

const SignUp = () => {

    const navigate = useNavigate()

    const formik = useCustomFormik({
        name: "",
        username: "",
        email: "",
        password: ""
    }, signupValidationSchema, async (values) => {
        // console.log('Form Data Submitted:', values);

        try {

            // eslint-disable-next-line
            const result = await axios.post(`${USER_API_END_POINT}/userSignup`, values, {
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: true
            });
            // console.log(result)
            if (result.data.success) {
                showToast(result.status, result.data.message)
                navigate("/login")
            }


        } catch (error) {
            // console.log(error)
            if (error.response) {
                const { status, data } = error.response

                showToast(status, data.message)
                // navigate("/reports")
            } else {
                showToast(500, "Some error occured")
            }
        }
    })

    return (
        <>
            <div className="sign-up-container text-center pt-5">
                <form className="sign-up-form" onSubmit={formik.handleSubmit}>
                    <h4 className="pb-3 ">Sign up</h4>
                    <input
                        placeholder="Full Name"
                        id='name'
                        type="text"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={` ${formik.touched.name && formik.errors.name ? 'error-input' : ''}`}
                        min={3}
                        maxLength={100}
                        onKeyDown={handleAlphabetsKeyDown}
                    />
                    {formik.touched.name && formik.errors.name && (
                        <div className="error">{formik.errors.name}</div>
                    )}
                    <input
                        placeholder="Username"
                        type="text"
                        name="username"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={` ${formik.touched.username && formik.errors.username ? 'error-input' : ''}`}
                        min={3}
                        maxLength={100}
                    />
                    {formik.touched.username && formik.errors.username && (
                        <div className="error">{formik.errors.username}</div>
                    )}

                    <input
                        placeholder="Email"
                        type="text"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={` ${formik.touched.email && formik.errors.email ? 'error-input' : ''}`}
                    />
                    {formik.touched.email && formik.errors.email && (
                        <div className="error">{formik.errors.email}</div>
                    )}
                    <input
                        placeholder="Password"
                        type="password"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={` ${formik.touched.password && formik.errors.password ? 'error-input' : ''}`}
                        minLength={3}
                        maxLength={100}

                    />
                    {formik.touched.password && formik.errors.password && (
                        <div className="error">{formik.errors.password}</div>
                    )}

                    <button className="btn btn-secondary mt-2 mb-2" type="submit">
                        Signup
                    </button>

                    <h6 className="pt-3">Already have an account?
                        <NavLink className='ms-1 customLink' to='/login'>
                            Login here
                        </NavLink>
                    </h6>
                </form>
            </div>
        </>
    );
};

export default SignUp;
