import React from 'react'
import "../Components/Purchase/Purchase.css"
import ReportScreen from '../Components/Report/ReportScreen'

const Report = () => {
    return (
        <>
            <div className="mt-2 pt-1">
                <ReportScreen />
            </div>
        </>
    )
}

export default Report