import React, { useEffect, useState } from "react";
import Select from "react-select";
import "../Purchase/Purchase.css";
import "../Sales/Sale.css"
import { customStyles } from "../Purchase/CustomStyleSelect";
import { handleSaleTypeChange, handleProductChange, handleProductInputChange, handleCustomerChange, handleCustomerInputChange } from "../../utils/handlers/inputChangeHandlers.js";
import CustomDropdownIndicator from "../Common/customDropDownIndcator.js";
import SalesGrid from "../Sales/SalesGrid.js";
import { useCustomFormik } from "../../configs/formik/formikConfig.js";
import { saleValidationSchema } from "../../configs/formik/validationSchema/saleValidation.js";
import { handleAlphanumericKeyDown, handleDigitAndDecimalKeyDown, handleDigitsOnlyKeyDown } from "../../utils/handlers/keyDownHandlers.js";
import { Input } from "../Common/SelectInputLength.js";
import { addProduct } from "./helper/addProductHelper.js";
import { calculateTotalAmount } from "./helper/calculateTotalAmount.js";
import { showToast } from "../../utils/handlers/toastHandler.js";
import { addSale } from "./services/addSaleService.js";
import { handleQuantitySoldChange } from "./helper/quantitySold.js";
import ConfirmationModal from "../Common/modal/ConfirmationModal.js";
import { formatCurrency } from "../../utils/currencyFormat.js";
import { validateSaleAmount } from "./helper/saleAmountValidation.js";
import { handleAdvanceChange } from "./helper/handleAdvance.js";

const SalesForm = () => {
    const [showDueDate, setShowDueDate] = useState(false);
    const [products, setProducts] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [productOptions, setProductOptions] = useState([]);
    const [customerOptions, setCustomerOptions] = useState([])
    const [quantitySold, setQuantitySold] = useState("");
    const [availableQuantity, setAvailableQuantity] = useState("");
    const [quantityError, setQuantityError] = useState(null);
    const [saleAmount, setSaleAmount] = useState("");
    const [color, setColor] = useState("");
    const [avgPrice, setAvgPrice] = useState("");
    const [totalAmount, setTotalAmount] = useState(0);
    const [isModalOpen, setModalOpen] = useState(false);  // Modal state
    const [availableQuantities, setAvailableQuantities] = useState({}); // Stores the actual available quantities
    const [saleAmountWarning, setSaleAmountWarning] = useState("");
    const [remainingAvailableQuantity, setRemainingAvailableQuantity] = useState("");
    const [isAdvanceValid, setIsAdvanceValid] = useState(true);


    useEffect(() => {
        setTotalAmount(calculateTotalAmount(products));
    }, [products]);

    const formik = useCustomFormik({
        customerName: "",
        customerPhone: "",
        saleType: "",
        dueDate: "",
        saleDate: new Date().toISOString().split("T")[0],
        advanceAmount: "",
        creditAmount: ""
    }, saleValidationSchema(totalAmount), (values) => {
        // Add products and totalAmount to the formik values before submission
        const { creditAmount, ...restValues } = values;
        // console.log(values)
        const submissionValues = {
            ...restValues,
            products,
            totalAmount,
        };
        if (products.length > 0) {
            // console.log("Form Data Submitted:", submissionValues);
            addSale(submissionValues)
        } else {
            showToast(400, "Please add products to record sale")
        }
        resetSalesForm(); // Reset form after submission
    })

    const handleAddProduct = () => {
        // Fallback to `availableQuantity` if `availableQuantities[selectedProduct.value]` is undefined
        const availableQuantityForProduct = availableQuantities[selectedProduct?.value] ?? availableQuantity;

        setRemainingAvailableQuantity(selectedProduct ? availableQuantities[selectedProduct.value] : ""); // Reset based on the new selection
        const result = addProduct(
            selectedProduct,
            quantitySold,
            saleAmount,
            color,
            avgPrice,
            setProducts,
            { ...availableQuantities, [selectedProduct?.value]: availableQuantityForProduct }
        );

        if (result) {
            const productId = result.selectedProduct.value;
            const soldQuantity = result.quantitySold;

            // Update central available quantities after adding the product
            setAvailableQuantities(prevQuantities => ({
                ...prevQuantities,
                [productId]: (prevQuantities[productId] || availableQuantityForProduct) - soldQuantity
            }));

            resetProductForm();
        }
    };


    // Function to reset product form after adding a product
    const resetProductForm = () => {
        setSelectedProduct(null);
        setQuantitySold("");
        setSaleAmount("");
        setColor("");
        setAvgPrice("");
        setAvailableQuantity("");
        setRemainingAvailableQuantity("");
        setProductOptions([])
    };

    // Function to reset entire sales form
    const resetSalesForm = () => {
        formik.resetForm();
        setProducts([]); // Clear the products array
        setTotalAmount(0);
        setShowDueDate(false);
        setProductOptions([])
    };


    // advance amount handler
    const handleAdvance = handleAdvanceChange(formik, totalAmount, setIsAdvanceValid);

    const handleAddClick = async (e) => {
        e.preventDefault();

        // Validate the form fields
        const errors = await formik.validateForm();

        // Check if customerName, customerPhone, and address fields are valid
        if (!errors.customerName && !errors.customerPhone && !errors.saleType) {
            setModalOpen(true); // Open the modal if all fields are valid
        } else {
            formik.setTouched({
                customerName: true,
                customerPhone: true,
                address: true
            });
        }
    };


    const toggleModal = () => setModalOpen(!isModalOpen);

    const handleConfirm = () => {
        formik.handleSubmit();  // Call form submission when modal is confirmed
        setModalOpen(false);
    };

    return (
        <>
            <div className="flex-container">
                <div className="form-container">
                    <div className="contactUsFormContainer col-lg-6">
                        <div className="contactUsForm pt-2 pb-2 " >
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <label htmlFor="productName">Product</label>
                                    <Select
                                        id="productName"
                                        name="productName"
                                        options={productOptions}
                                        isSearchable
                                        onChange={handleProductChange(setSelectedProduct, setAvailableQuantity, setColor, setAvgPrice, products)}
                                        onInputChange={handleProductInputChange(setProductOptions)}
                                        value={selectedProduct ? { label: selectedProduct.label, value: selectedProduct.value } : null}
                                        styles={customStyles}
                                        components={{ DropdownIndicator: CustomDropdownIndicator, Input }}
                                        placeholder="Select product"
                                        noOptionsMessage={() => 'Type to search product'}
                                        onKeyDown={handleAlphanumericKeyDown}
                                    />

                                </div>
                                <div className="col-12 col-lg-6">
                                    <label htmlFor="avgPrice">Avg Price</label>
                                    <input
                                        type="text"
                                        id="avgPrice"
                                        name="avgPrice"
                                        placeholder="0.0"
                                        value={avgPrice}
                                        readOnly
                                    />
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <label htmlFor="availableQuantity">Quantity Available</label>
                                    <input
                                        type="text"
                                        id="availableQuantity"
                                        name="availableQuantity"
                                        placeholder="0"
                                        value={remainingAvailableQuantity !== "" ? remainingAvailableQuantity : availableQuantity}
                                        readOnly
                                    />
                                </div>
                                <div className="col-12 col-lg-6">
                                    <label htmlFor="color">Color</label>
                                    <input
                                        type="text"
                                        id="color"
                                        name="color"
                                        placeholder="0"
                                        value={color}
                                        readOnly
                                    />
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <label htmlFor="quantitySold">Quantity Sold</label>
                                    <input
                                        type="text"
                                        id="quantitySold"
                                        name="quantitySold"
                                        placeholder="0"
                                        className={quantityError ? "error-input" : ''}
                                        value={quantitySold}
                                        onKeyDown={handleDigitsOnlyKeyDown}
                                        onChange={handleQuantitySoldChange(setQuantitySold, setQuantityError, availableQuantity, setRemainingAvailableQuantity)}


                                    />
                                    {quantityError && <div className="error">{quantityError}</div>} {/* Error message */}
                                </div>

                                <div className="col-12 col-lg-6">
                                    <label htmlFor="saleAmount">Amount</label>
                                    <input
                                        type="text"
                                        id="saleAmount"
                                        name="saleAmount"
                                        placeholder="0.0"
                                        value={saleAmount}
                                        onChange={(e) => {
                                            setSaleAmount(e.target.value);
                                            validateSaleAmount(e.target.value, quantitySold, avgPrice, setSaleAmountWarning);
                                        }}
                                        onKeyDown={handleDigitAndDecimalKeyDown}
                                        className={saleAmountWarning ? "warning-input" : ""}
                                    />
                                    {saleAmountWarning && <div className="warning">{saleAmountWarning}</div>}
                                </div>


                            </div>
                            <div className="row">

                                <div className="col-12">
                                    <div className="text-center text-lg-end formButtons">
                                        <button
                                            className="btn btn-secondary me-2"
                                            type="button"
                                            onClick={handleAddProduct}
                                            disabled={!!quantityError}
                                        >
                                            Add
                                        </button>
                                        <button
                                            className="btn btn-secondary"
                                            type="button"
                                            onClick={resetProductForm}  // Reset product form
                                        >
                                            Reset
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Sales Ag-grid table */}
                <div className="grid-container">
                    <SalesGrid products={products} setProducts={setProducts} setAvailableQuantities={setAvailableQuantities} />
                </div>
                <div>

                </div>
            </div>

            {/* Total Amount */}
            <div className="d-flex mt-2">
                <div className="col-12 col-lg-6 d-lg-flex d-none" ></div>
                <div className="col-12 col-lg-6 card">
                    <div className="card-body d-flex justify-content-between">
                        <div className="totalAmount">Total Amount</div>
                        <div className="totalAmount">{formatCurrency(totalAmount)}</div>
                    </div>
                </div>

            </div>
            <form className="row mt-2 justify-content-between" >
                <div className={` ${showDueDate ? 'col-12 col-lg-2' : 'col-12 col-lg-4'} `}>
                    <label htmlFor="customerName">Customer Name</label>
                    <Select
                        id='customerName'
                        name="customerName"
                        placeholder="Select Customer"
                        menuPlacement="top"
                        className={`${formik.touched.customerName && formik.errors.customerName ? '' : ''}`}
                        options={customerOptions}
                        onChange={handleCustomerChange(formik)}
                        onInputChange={handleCustomerInputChange(setCustomerOptions, formik)}
                        value={formik.values.customerName ? customerOptions.find(customerOptions => customerOptions.label === formik.values.customerName) || { label: formik.values.customerName, value: formik.values.customerName } : null}
                        styles={customStyles}
                        components={{ DropdownIndicator: CustomDropdownIndicator }}
                        noOptionsMessage={() => 'Type to search customer'}
                        onBlur={formik.handleBlur}
                        isSearchable
                        error={formik.touched.customerName && formik.errors.customerName}
                        onKeyDown={handleAlphanumericKeyDown}
                    />
                    {formik.touched.customerName && formik.errors.customerName && (
                        <div className="error">{formik.errors.customerName}</div>
                    )}
                </div>
                <div className={` ${showDueDate ? 'col-12 col-lg-2' : 'col-12 col-lg-4'} `}>
                    <label htmlFor="customerPhone">Phone Number</label>
                    <input
                        type="text"
                        id="customerPhone"
                        name="customerPhone"
                        placeholder="Type here"
                        readOnly
                        value={formik.values.customerPhone}
                        onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d{0,11}$/.test(value)) {
                                formik.handleChange(e);
                            }
                        }}
                        className={`${formik.touched.customerPhone && formik.errors.customerPhone ? 'error-input' : ''}`}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.customerPhone && formik.errors.customerPhone && (
                        <div className="error">{formik.errors.customerPhone}</div>
                    )}
                </div>
                <div className={` ${showDueDate ? 'col-12 col-lg-2' : 'col-12 col-lg-4'} `}>
                    <label htmlFor="saleType">Type</label>
                    <select
                        id="saleType"
                        name="saleType"
                        value={formik.values.saleType}
                        onChange={handleSaleTypeChange(formik, setShowDueDate)}
                        className={`${formik.touched.saleType && formik.errors.saleType ? 'error-input' : ''}`}
                        onBlur={formik.handleBlur}
                    >
                        <option value="" disabled>
                            Select Type
                        </option>
                        <option value="Cash">Cash</option>
                        <option value="Credit">Credit</option>
                    </select>
                    {formik.touched.saleType && formik.errors.saleType && (
                        <div className="error">{formik.errors.saleType}</div>
                    )}
                </div>

                {showDueDate && (
                    <>
                        <div className="col-12 col-lg-2">
                            <label htmlFor="advanceAmount">Advance</label>
                            <input
                                type="text"
                                id="advanceAmount"
                                name="advanceAmount"
                                placeholder="0.0"
                                value={formik.values.advanceAmount}
                                onChange={handleAdvance}// Pass totalAmount here
                                onKeyDown={handleDigitAndDecimalKeyDown}
                                className={`${formik.touched.advanceAmount && formik.errors.advanceAmount ? 'error-input' : ''}`}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.advanceAmount && formik.errors.advanceAmount && (
                                <div className="error">{formik.errors.advanceAmount}</div>
                            )}
                        </div>
                        <div className="col-12 col-lg-2">
                            <label htmlFor="advanceAmount">Credit Amount</label>
                            <input
                                type="text"
                                id="creditAmount"
                                name="creditAmount"
                                placeholder="0.0"
                                value={formik.values.creditAmount}
                                onChange={handleAdvanceChange(formik, totalAmount)}
                                readOnly
                            />

                        </div>
                        <div className="col-12 col-lg-2">
                            <label htmlFor="dueDate">Due Date</label>
                            <input
                                type="date"
                                id="dueDate"
                                name="dueDate"
                                value={formik.values.dueDate}
                                onChange={formik.handleChange}
                                className={`${formik.touched.dueDate && formik.errors.dueDate ? 'error-input' : ''}`}
                                onBlur={formik.handleBlur}
                                placeholder="Due Date"
                            />
                            {formik.touched.dueDate && formik.errors.dueDate && (
                                <div className="error">{formik.errors.dueDate}</div>
                            )}
                        </div>
                    </>

                )}
                <div className='col-12 mb-2'>
                    <div className="text-center text-lg-end formButtons">
                        <button type="submit" className="btn btn-secondary me-2 "
                            onClick={handleAddClick}
                            disabled={!isAdvanceValid}  //
                        >
                            Done
                        </button>
                        <button
                            className="btn btn-secondary"
                            type="button"
                            onClick={resetSalesForm}  // Reset product form
                        >
                            Reset
                        </button>
                    </div>
                </div>
            </form>

            {/* Reusable Modal */}
            <ConfirmationModal
                isOpen={isModalOpen}
                toggle={toggleModal}
                onConfirm={handleConfirm}   // Submit form on confirm
                message="Are you sure you want to record this sale?"
            />
        </>
    );
};

export default SalesForm;
