import axios from "axios"
import { CREDIT_API_END_POINT } from "../../../utils/constants/apiUrls"
import { showToast } from "../../../utils/handlers/toastHandler"


export const getCustomerBalance = async (value) => {
    try {
        // console.log(value);
        const result = await axios.post(`${CREDIT_API_END_POINT}/getCustomerBalance`, value, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        })
        // console.log(result);
        if (result.data) {
            return result.data.credit_balance
        }

    } catch (error) {
        // console.log(error)
        if (error.response) {
            const { status, data } = error.response

            showToast(status, data.message)

            return "0.0"

        } else {
            showToast(500, "Some error occured")
        }
    }
}

export const submitCreditReceive = async (values) => {
    try {
        const result = await axios.post(`${CREDIT_API_END_POINT}/creditReceived`, values, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        })
        // console.log(result)

        if (result.data.success) {
            showToast(200, result.data.message);
            return result.data;  // Return the response for further use
        }

    } catch (error) {
        // console.log(error)
        if (error.response) {
            // console.log(error.response)
            const { status, data } = error.response

            showToast(status, data.message)

        } else {
            showToast(500, "Some error occured")
        }
    }
}


export const fetchCreditHistory = async (customerName) => {
    // console.log(customerName);
    try {
        const response = await axios.post(`${CREDIT_API_END_POINT}/getCreditHistory`, { customerName }, {
            withCredentials: true,
        });
        // console.log(response);
        if (response.data.success) {
            return response.data.result;
        }
    } catch (error) {
        console.log(error)
        if (error.response) {
            console.log(error.response)
            const { status, data } = error.response

            showToast(status, data.message)

        } else {
            showToast(500, "Some error occured")
        }
    }
};
