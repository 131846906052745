// logoutService.js
import axios from "axios";
import { USER_API_END_POINT } from "../../../../utils/constants/apiUrls";
import { showToast } from "../../../../utils/handlers/toastHandler";

// Logout API call
export const logoutUser = async () => {
    console.log("logout user");
    try {
        const response = await axios.post(`${USER_API_END_POINT}/userLogout`, {}, {
            withCredentials: true,
        });

        console.log(response);

        if (response.status === 200) {
            showToast(200, "User logged out successfully")
        }
        return

    } catch (error) {
        console.error("Logout error:", error);
        
        return 
    }
};
