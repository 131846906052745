import axios from "axios"
import { showToast } from "../../../utils/handlers/toastHandler"
import { PURCHASE_API_END_POINT } from "../../../utils/constants/apiUrls"

export const addProduct = async (values) => {

    // console.log(values)
    try {

        const result = await axios.post(`${PURCHASE_API_END_POINT}/addPurchase`, values, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        })

        // console.log(result)
        if (result.data.success) {
            showToast(result.status, result.data.message)
        }

    } catch (error) {
        if (error.response) {
            const { status, data } = error.response

            showToast(status, data.message)

        } else {
            showToast(500, "Some error occured")
        }
    }
}


export const searchProduct = async (productName) => {
    // console.log(productName)
    try {
        const result = await axios.get(`${PURCHASE_API_END_POINT}/searchProductForPurchase?productName=${encodeURIComponent(productName)}`, {
            withCredentials: true
        })

        // console.log(result)

        return result

    } catch (error) {
        if (error.response) {
            const { status, data } = error.response

            showToast(status, data.message)

        } else {
            showToast(500, "Some error occured")
        }

        return []
    }
}